import { ExclamationCircleOutlined, FrownOutlined } from "@ant-design/icons"
import { Avatar, Collapse, notification, Popover, Result, Tag } from "antd"
// import "antd/dist/antd.less"
import dayjs from "dayjs"
import React, { useEffect, useState } from "react"
import Countdown from "react-countdown"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useParams } from "react-router-dom"
import styled from "styled-components"
import SchoolSVG from "~/assets/images/school.svg"
import Container, { MainContainer, MainContent } from "~/components/common/Container"
import ContentHeader from "~/components/common/ContentHeader"
import DefaultButton from "~/components/common/DefaultButton"
import H1 from "~/components/common/H1"
import Loading from "~/components/common/Loading"
import PrimaryButton from "~/components/common/PrimaryButton"
import { deleteOrder, fetchOrders, payOrder, validateOrder } from "~/containers/Cart/actions"
import EmptyCartPopUp from "~/containers/Cart/popups/emptyCartPopUp"
import PaymentResultPopUp from "~/containers/Cart/popups/paymentResultPopUp"
import ValidateOrderPopUp from "~/containers/Cart/popups/validateOrderPopup"
import { colors } from "~/utils/colors"

dayjs.locale("fr")

const { Panel } = Collapse

const Cart = () => {
  const location = useLocation()
  const [showPaymentResultModal, setShowPaymentResultModal] = useState(false)
  const [hasRegularization, setHasRegularization] = useState(false);

  const {
    orders: {
      isFetching: isFetchingOrders,
      data: orders,
      error: ordersError,
      isValidating: isValidatingOrders,
    },
  } = useSelector((state) => state.cart)

  useEffect(() => {
    // Safely check for regularization
    const checkRegularization = orders && orders.some(order =>
      order && order.attendances && Object.values(order.attendances).some(child =>
        child && Object.values(child).some(activity =>
          activity && activity.regul && activity.regul.length > 0
        )
      )
    );
    setHasRegularization(checkRegularization);
  }, [orders]);

  const paymentStatus = location.search.includes("?payment_result=") && location.search.split("?payment_result=")?.[1]?.[0]

  console.log({paymentStatus, location})

  useEffect(() => {
    if (
      paymentStatus
      // && curOrderPayment
    ) {
      setShowPaymentResultModal(true)
    }
  }, [paymentStatus])

  return (
    <>
      <ContentHeader
        breadcrumbItems={[
          {
            name: "Panier",
            path: "/panier",
          },
        ]}
      >
        <H1>Panier</H1>
      </ContentHeader>
      {!isFetchingOrders && !orders?.length && (
        <Container>
          <Result
            icon={<FrownOutlined />}
            title="Votre panier est vide!"
          />
        </Container>
      )}
      {!isFetchingOrders && orders?.length > 0 && (
        <CollapseWrapper>
          <Collapse
            defaultActiveKey={["0"]}
            expandIconPosition="right"
            bordered={false}
          >
            {orders.map((order, index) => (
              <ContainerWrapper>
                <OrgOrder
                  key={index}
                  index={index}
                  order={order}
                  hasRegularization={hasRegularization}
                />
              </ContainerWrapper>
            ))}
          </Collapse>
        </CollapseWrapper>
      )}
      {isFetchingOrders && (
        <Container>
          <Loading />
        </Container>
      )}
      <PaymentResultPopUp
        showPaymentResultModal={showPaymentResultModal}
        paymentStatus={paymentStatus}
        setShowPaymentResultModal={setShowPaymentResultModal}
      />
    </>
  )
}

const OrgOrder = ({
  order,
  index,
  hasRegularization,
  ...props
}) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const {
    alias: orderAlias,
    organisation_name: organisationName,
    organisation_alias: organisationAlias,
    attendances,
    amount: organisationOrderInitialAmount,
    total_amount: organisationOrderAmount,
    credit,
    deactivate_paiement_methods: deactivatePaimentMethods,
    expiration_duration: expirationDuration,
    expires_at: expiresAt,
    status,
  } = order || {}

  const expiresAtDate = new Date(expiresAt)
  // const organisationOrderAmount = organisationOrderInitialAmount - credit

  const [isValidateOrderModalVisible, setIsValidateOrderModalVisible] = useState(false)
  const [isEmptyCartModalVisible, setIsEmptyCartModalVisible] = useState(false)

  const userEmail = useSelector((state) => state.user.auth.user.email)

  const showOtherPaymentMethodsButton = !deactivatePaimentMethods

  // const payCartOnTheSpot = async () => {
  //   try {
  //     let response = await axios.post(`/users/${userEmail}/prepaidOrders/`, {})

  //     if (response?.data.alias) {
  //       setPaymentOnTheSpotSuccessful(true)
  //     }
  //   } catch (e) {
  //     console.warn({e})
  //   }
  // }

  const handleValidateOrder = (orderAlias) => {
    setIsValidateOrderModalVisible(true)
    dispatch(
      validateOrder({
        orderAlias,
        closeModal: () => setIsValidateOrderModalVisible(false),
      })
    )
  }

  const handleDeleteOrder = (orderAlias) => {
    dispatch(
      deleteOrder({
        orderAlias,
        redirecturl: location.pathname,
        closeModal: () => setIsEmptyCartModalVisible(false),
      })
    )
  }

  const handlePayOrder = (orderAlias) => {
    dispatch(
      payOrder({
        orderAlias,
        redirecturl: location.pathname,
        closeModal: () => setIsValidateOrderModalVisible(false),
      })
    )
  }

  const handlePayOrderOnTheSpot = (orderAlias) => {
    dispatch(
      payOrder({
        orderAlias,
        redirecturl: location.pathname,
        is_payment_on_spot: true,
      })
    )
  }
  const renderedCountdown = ({ days, hours, minutes, seconds, completed }) => {
    console.log({completed, days, hours})
    if (completed) {
      // dispatch(fetchOrders())

      return <span style={{color: colors.cvRed, fontWeight: "bold"}}>+ que quelques s</span>
    } else {
      // Render a countdown
      return (
        <span style={{color: colors.cvRed, fontWeight: "bold"}}>
          {`${days ? `${days}j ` : ""}${hours ? `${hours}h ` : ""}`}
          {("0"+minutes).slice(-2)}:{("0"+seconds).slice(-2)}
        </span>
      );
    }
  };

  const groupAndFormatDates = (dates) => {
    const grouped = {};
    const monthMap = {
      "janv.": 0, "févr.": 1, "mars": 2, "avr.": 3, "mai": 4, "juin": 5,
      "juil.": 6, "août": 7, "sept.": 8, "oct.": 9, "nov.": 10, "déc.": 11
    };
    const monthAbbreviations = {
      "janvier": "janv.", "février": "févr.", "mars": "mars", "avril": "avr.",
      "mai": "mai", "juin": "juin", "juillet": "juil.", "août": "août",
      "septembre": "sept.", "octobre": "oct.", "novembre": "nov.", "décembre": "déc."
    };

    const parseDate = (dateString) => {
      const dateParts = dateString.split("-");
      if (dateParts.length === 3) {
        return new Date(Date.UTC(parseInt(dateParts[0]), parseInt(dateParts[1]) - 1, parseInt(dateParts[2])));
      }
      const parts = dateString.split(" ");
      if (parts.length === 4) {
        const [dow, dayOfMonth, monthName, year] = parts;
        const month = monthMap[monthName.trim()];
        return new Date(Date.UTC(parseInt(year), month, parseInt(dayOfMonth)));
      } else if (parts.length >= 4 && parts[3].includes(":")) {
        // Special format mar. 9 juil. 24: 2h
        const [dow, dayOfMonth, monthName, yearTime] = parts;
        const [year, time] = yearTime.split(":");
        const month = monthMap[monthName.trim()];
        return new Date(Date.UTC(2000 + parseInt(year.trim()), month, parseInt(dayOfMonth)));
      }
      return null;
    };

    const sortedDates = [...dates].sort((a, b) => {
      const dateA = parseDate(a);
      const dateB = parseDate(b);
      return dateA - dateB;
    });

    sortedDates.forEach(date => {
      const dateObj = parseDate(date);
      if (dateObj) {
        const fullMonthName = dateObj.toLocaleString("fr-FR", { month: "long" });
        const monthName = monthAbbreviations[fullMonthName] || fullMonthName;
        const dayName = dateObj.toLocaleString("fr-FR", { weekday: "short" });
        const year = dateObj.getUTCFullYear();
        const day = dateObj.getUTCDate();
        const key = `${monthName} ${year.toString().substring(2)}`;
        const formattedDay = `${dayName} ${day}`;

        if (!grouped[key]) {
          grouped[key] = [];
        }
        grouped[key].push(formattedDay);
      }
    });

    return Object.entries(grouped)
      .map(([key, value]) => `<strong>${key}:</strong> ${value.join(", ")}`)
      .join("<br>");
  };

  const renderDateInfo = (dates, label) => {
    if (dates && dates.length > 0) {
      return (
        <div>
          {label}: <span style={{color: "#969696"}} dangerouslySetInnerHTML={{ __html: groupAndFormatDates(dates) }}></span>
        </div>
      );
    }
    return (
      <div>
        {label}: <span style={{color: "#969696"}}>-</span>
      </div>
    );
  }

  return (
    <>
      <Panel
        {...props}
        key={index}
        header={
          <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", width: "95%"}}>
            <div>
              <Avatar size={42} src={SchoolSVG} />
              <OrgName>
                Panier pour {organisationName} {status === 2 ? <Tag color="orange">En cours de paiement</Tag> : ""}
              </OrgName>
            </div>
            {expiresAtDate instanceof Date && !isNaN(expiresAtDate.valueOf()) && (
              <p style={{marginBottom: 0}}>
                Validité panier ({expirationDuration} min.) :{" "}
                <Countdown
                  date={expiresAtDate}
                  renderer={renderedCountdown}
                />
              </p>
            )}
          </div>
        }
      >
        <div>
          {Object.keys(attendances).map((childAlias) => {
            const child = attendances?.[childAlias];
            const anActivityAlias = Object.keys(child).find(item => item !== "total");

            const childName = child?.[anActivityAlias]?.child_name;
            const activities = child;
            let childTotal = child?.total;
            return (
              <div key={childAlias}>
                <LabelContainer>
                  <FirstLabel>{childName}</FirstLabel>
                  <Label>Quantité</Label>
                  <Label>Prix unitaire</Label>
                  <Label>Montant</Label>
                </LabelContainer>
                {Object.keys(activities)?.filter(item => item !== "total").map((activityAlias) => {
                  const activity = activities?.[activityAlias]
                  const childFirstName = childName?.split(" ")?.[0]
                  const isRegul = activity?.regul !== undefined && activity?.regul?.length > 0;

                  if(!isRegul){
                    const presenceDatesWithSubscription = activity?.InscriptionDates
                    const presenceDatesWithoutSubscription = activity?.presenceWithNoInscriptionDatesWithoutRegul
                    const activityName = activity?.parentActivity_name
                    const activityQuantity = activity?.quantity
                    const activityAmount = activity?.amount
                    const childActivityTotal = activity?.total
                    return (
                      <Line>
                        <FirstCol>
                          {activityName}
                          <Popover
                            placement="right"
                            content={
                              <div style={{ maxWidth: "600px", overflow:"auto" }}>
                                <div
                                  style={{
                                    marginBottom: 20,
                                  }}
                                >
                                  <ExclamationCircleOutlined
                                    style={{
                                      marginRight: 6,
                                      fontSize: 18,
                                      color: "#F4C26D",
                                    }}
                                  />
                                  {organisationName} / {activityName} / {childFirstName}
                                </div>
                                {presenceDatesWithSubscription && presenceDatesWithSubscription?.length>0 && renderDateInfo(presenceDatesWithSubscription, "Réservation")}
                                {presenceDatesWithoutSubscription && presenceDatesWithoutSubscription?.length>0 && renderDateInfo(presenceDatesWithoutSubscription, "Présence sans inscription") }
                              </div>
                            }
                            trigger="click"
                          >
                            <ExclamationCircleOutlined
                              style={{
                                marginLeft: 5,
                                fontSize: 12,
                                color: "#646464",
                              }}
                            />
                          </Popover>
                        </FirstCol>
                        <div>{activityQuantity}</div>
                        <div>
                          {activityAmount.toFixed(2)}{" "} €
                        </div>
                        <div>
                          {childActivityTotal.toFixed(2)} €
                        </div>
                      </Line>
                    )
                  }else{
                    const presenceDatesWithSubscription = activity?.InscriptionDates || [];
                    const presenceDatesWithoutSubscription = activity?.presenceWithNoInscriptionDatesWithoutRegul || [];
                    const presenceDatesWithoutSubscriptionRegularized = activity?.presenceWithNoInscriptionDatesWithRegul || [];
                    const attendancesRegularization = activity?.regul || [];
                    const activityName = activity?.parentActivity_name;
                    const activityInitialAmount = activity?.amount;

                    let presenceWithNoInscriptionAmount = 0;
                    let inscriptionAmount = 0;
                    let inscriptionQuantity = 0;
                    let presenceWithNoInscriptionQuantity = 0;
                    let inscriptionTotal =  0;
                    // let regulPrice = 0;
                    let presenceWithNoInscriptionTotal = 0;

                    if (attendancesRegularization?.length > 0) {
                      attendancesRegularization?.map((reg, index) => {
                        Object.entries(reg).map(([regName, regDetails]) => {
                          // Parse as float without toFixed(2)
                          let quantity = parseFloat(regDetails.quantity);
                          let totalPrice = parseFloat(regDetails.total_price);
                          let unitPrice = parseFloat(regDetails.unit_price);
                          // Store parsed values for later use
                          presenceWithNoInscriptionQuantity = quantity; //parseFloat(presenceDatesWithoutSubscription?.length)
                          presenceWithNoInscriptionTotal = totalPrice.toFixed(2);
                          presenceWithNoInscriptionAmount = unitPrice.toFixed(2);
                        });
                      });
                    }

                    if (presenceDatesWithSubscription?.length>0 || presenceDatesWithoutSubscription?.length>0 ){
                      // Perform arithmetic operations with parsed float values
                      let amount = activityInitialAmount;
                      let quantity = presenceDatesWithSubscription?.length + presenceDatesWithoutSubscription?.length;
                      let total = quantity * amount;
                      // Convert to string with two decimal places for display
                      inscriptionAmount = amount.toFixed(2);
                      inscriptionQuantity = quantity;
                      inscriptionTotal = total.toFixed(2);
                    }
                    return (
                      <>
                        {((presenceDatesWithSubscription && presenceDatesWithSubscription?.length>0) || (presenceDatesWithoutSubscription && presenceDatesWithoutSubscription?.length > 0))  && (
                          <Line>
                            <FirstCol>
                              {activityName}
                              <Popover
                                placement="right"
                                content={
                                  <div style={{ maxWidth: "600px", overflow:"auto" }}>
                                    <div style={{ marginBottom: 20 }}>
                                      <ExclamationCircleOutlined style={{ marginRight: 6, fontSize: 18, color: "#F4C26D" }} />
                                      {organisationName} / {activityName} / {childFirstName}
                                    </div>
                                    {presenceDatesWithSubscription && presenceDatesWithSubscription?.length>0 && renderDateInfo(presenceDatesWithSubscription, "Réservation")}
                                    {presenceDatesWithoutSubscription && presenceDatesWithoutSubscription?.length > 0 && renderDateInfo(presenceDatesWithoutSubscription, "Présence sans inscription")}
                                  </div>
                                }
                                trigger="click"
                              >
                                <ExclamationCircleOutlined style={{ marginLeft: 5, fontSize: 12, color: "#646464" }} />
                              </Popover>
                            </FirstCol>
                            <div>{inscriptionQuantity}</div>
                            <div>{inscriptionAmount} €</div>
                            <div>{inscriptionTotal} €</div>
                          </Line>
                        )}
                        {presenceDatesWithoutSubscriptionRegularized && presenceDatesWithoutSubscriptionRegularized?.length > 0 && (
                          <Line isRegul={isRegul}>
                            <FirstCol>
                              {activityName}
                              <Popover
                                placement="right"
                                content={
                                  <div style={{ maxWidth: "600px", overflow:"auto" }}>
                                    <div style={{ marginBottom: 20 }}>
                                      <ExclamationCircleOutlined style={{ marginRight: 6, fontSize: 18, color: "#F4C26D" }} />
                                      {organisationName} / {activityName} / {childFirstName}
                                    </div>
                                    {/*renderDateInfo(presenceDatesWithoutSubscription, "Présence sans inscription")*/}
                                    <div>
                                      {/* Régularisation: */}
                                      {attendancesRegularization?.length > 0 ? (
                                        <div style={{ color: "#969696" }}>
                                          {attendancesRegularization?.map((reg, index) =>
                                            Object.entries(reg).map(([regName, regDetails]) => (
                                              <div key={`${regName}-${index}`}>
                                                <strong>{regName}</strong>
                                                <div>Quantité: {regDetails.quantity}</div>
                                                <div>Prix unitaire: {regDetails.unit_price} €</div>
                                                <div>Prix total: {regDetails.total_price} €</div>
                                                <div>{regDetails.legends}</div>
                                                <div>Dates: <span dangerouslySetInnerHTML={{ __html: groupAndFormatDates(regDetails.att.map(att => att.display_name))}}></span></div>
                                              </div>
                                            ))
                                          )}
                                        </div>
                                      ) : (
                                        <span style={{ color: "#969696" }}>-</span>
                                      )}
                                    </div>
                                  </div>
                                }
                                trigger="click"
                              >
                                <ExclamationCircleOutlined style={{ marginLeft: 5, fontSize: 12, color: isRegul?"red":"#646464" }} />
                              </Popover>
                            </FirstCol>
                            <div>{presenceWithNoInscriptionQuantity}</div>
                            <div>{presenceWithNoInscriptionAmount} €</div>
                            <div>{presenceWithNoInscriptionTotal} €</div>
                          </Line>
                        )}
                      </>
                    );
                  }

                })}
                <SubTotalContainer>
                  <SubTotalLabel> SOUS-TOTAL </SubTotalLabel>
                  <Bold>{childTotal} €</Bold>
                </SubTotalContainer>
              </div>
            );
          })}
        </div>
        {hasRegularization && (
          <LegendContainer>
            <span style={{ border: "1px dashed red", padding: "0 4px" }}>
              <ExclamationCircleOutlined style={{color:"red"}} />
            </span> Indique une régularisation
          </LegendContainer>
        )}
      </Panel>
      <FlexEven>
        <div>
          <DefaultButton
            size="large"
            style={{ background: "#F2F4F6", borderColor: "#F2F4F6", color: "#646464", margin: 10 }}
            onClick={() => setIsEmptyCartModalVisible(true)}
          >
            {status === 2 ? "Abondonner le panier" : "Vider le panier"}
          </DefaultButton>
          <PrimaryButton onClick={() => handleValidateOrder(orderAlias)} size="large">
            {status === 2 ? "Continuer le paiement" : "Valider mon panier"}
          </PrimaryButton>
        </div>
        <TotalContainer>
          <PriceLabel>TOTAL</PriceLabel>
          <Bold>{organisationOrderInitialAmount.toFixed(2)} €</Bold>
          <PriceLabel>AVOIR</PriceLabel>
          <Bold>{credit.toFixed(2)} €</Bold>
          <PriceLabel>TOTAL A PAYER</PriceLabel>
          <Bold>{organisationOrderAmount.toFixed(2)} €</Bold>
        </TotalContainer>
      </FlexEven>
      <EmptyCartPopUp
        visible={isEmptyCartModalVisible}
        setVisible={setIsEmptyCartModalVisible}
        emptyCart={() => handleDeleteOrder(orderAlias)}
      />
      <ValidateOrderPopUp
        visible={isValidateOrderModalVisible}
        setVisible={setIsValidateOrderModalVisible}
        showOtherPaymentMethodsButton={showOtherPaymentMethodsButton}
        payCartOnTheSpot={() => handlePayOrderOnTheSpot(orderAlias)}
        payCartByCB={() => handlePayOrder(orderAlias)}
      />
    </>
  )
}

const formatPayCart = (cart, orgAlias) => {
  let child_subscriptions = [];
  for (let c of cart) {

    child_subscriptions.push({
      child_alias: c.childAlias,
      activityAlias: c.sub.parent_activity_alias, // parent_activity_alias
      activityBuildingAlias: c.activityAlias,
      dates: [c.date],
    })
  }
  return {
    child_subscriptions,
    organisation_alias: orgAlias,
  };
};

const ContainerWrapper = ({ children, ...props }) => (
  <MainContainer {...props}>
    <MainContent>{React.cloneElement(children, {...props})}</MainContent>
  </MainContainer>
)

const FlexEven = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`

const Bold = styled.span`
  font-weight: bold;
  margin-left: 20px;
`

const SubTotalContainer = styled.div`
  border-top: 1px solid #e2e4e6;
  margin-top: 5px;
  padding-top: 5px;
  text-align: right;
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const SubTotalLabel = styled.span`
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: @neutral-4;
`
const LabelContainer = styled.div`
  display: flex;
  border-bottom: 1px solid #e2e4e6;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
  line-height: 20px;
  padding-bottom: 5px;
  margin-bottom: 5px;
`

const Label = styled.div`
  flex: 1;
  text-align: right;
  padding-right: 10px;
  align-items: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: @neutral-4;
`

const Line = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 16px;
  line-height: 24px;
  border: ${({ isRegul }) => isRegul ? "1px dashed red" : "none"};
  & > div {
    flex: 1;
    text-align: right;
    padding-right: 10px;
  }
  & > div:first-child {
    flex: 3;
    text-align: left;
  }
`

const FirstCol = styled.div`
  flex: 3;
  text-align: left;
  width: 200px;
`

const FirstLabel = styled.div`
  flex: 3;
  text-align: left;
  width: 200px;
  align-items: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: @neutral-4;
`
const TotalContainer = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: end;
`

const PriceLabel = styled.span`
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  color: @neutral-4;
  margin-right: 16px;
  margin-left: 32px;
`

const CollapseWrapper = styled.div`
  .ant-collapse-header {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }

  .ant-collapse-content {
    border-top: none;
  }

  .anticon-right {
    font-size: 32px;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding-bottom: 0;
  }
`

const OrgName = styled.span`
  font-style: normal;
  font-weight: normal;
  line-height: 28px;
  color: @neutral-5;
  font-size: 20px;
  margin-bottom: 0px;
  margin-left: 10px;
  padding-bottom: 0px;
`
const LegendContainer = styled.div`
  text-align: left;
  font-size: 12px;
  color: #646464; // Adjust the color to fit your theme
  margin-top: 20px; // Space from the last content
  margin-left: 10px; // Align to the left within the panel
`;
export default Cart
