import React, { useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import Image from "~/components/common/Image"
import ActivityIcon from "~/components/common/ActivityIcon"
import Date from "~/components/common/Date"
import Href from "~/components/common/Href"
import styled from "styled-components"
import { Divider, Button } from "antd"
import Space from "~/components/common/Space"
import Table from "~/components/common/Table"
import { BankOutlined, PhoneOutlined, MailOutlined } from "@ant-design/icons"
import { useColumns } from "./columns"

import {
  ActivityContainer,
  PhotoConainer,
  ContentConainer,
  DetailMainTitle,
  DetailContent,
  DetailContainer,
  DetailTitle,
} from "./index"
import dayjs from "dayjs"
import { colors } from "~/utils/colors"
import { getBillingPeriodLabel, getPeriodSelectionLabel } from "~/constants"
import Text from "~/components/common/Text"

const ActivityDetails = ({ activity, isHouseholdExternal, organisation, setCurrentStep }) => {
  const { t } = useTranslation("subscription")

  console.log({activity, organisation})
  // useEffect(() => {
  //   axios.get("organisations​/​/activities​/​/").then(res => {
  //     
  //   })
  // }, []);

// ​/api​/organisations​/{organisations_alias}​/activities​/{alias}​/

  const orderedPricePlanForNormal = useMemo(() => {
    if (activity) {
      return activity?.pricePlan?.priceplanitemSet?.sort((a, b) => a.category.minValue - b.category.minValue);
    }

    return null;
  }, [activity])

  const orderedPricePlanForExternal = useMemo(() => {
    if (activity) {
      return activity?.pricePlanForExternal?.priceplanitemSet?.sort((a, b) => a.category.minValue - b.category.minValue);
    }

    return null;
  }, [activity])

  const pricePlanItems = useMemo(() => {
    if (isHouseholdExternal) {
      return orderedPricePlanForExternal;
    }

    return orderedPricePlanForNormal;
  }, [isHouseholdExternal, orderedPricePlanForExternal, orderedPricePlanForNormal])

  const pricePlanForNormalBillingModel = useMemo(() => {
    if (activity) {
      return activity?.pricePlan?.billingModel?.key;
    }
    return null;
  }, [activity])

  const pricePlanForExternalBillingModel = useMemo(() => {
    if (activity) {
      return activity?.pricePlanForExternal?.billingModel?.key;
    }

    return null;
  }, [activity])

  const pricePlanBillingModel = useMemo(() => {
    if (isHouseholdExternal) {
      return pricePlanForExternalBillingModel;
    }

    return pricePlanForNormalBillingModel;
  }, [isHouseholdExternal, pricePlanForExternalBillingModel, pricePlanForNormalBillingModel])

  const pricePlanForNormalBillingModelTimeUnit = useMemo(() => {
    if (pricePlanBillingModel === 1) {
      return activity?.pricePlan?.timeUnit;
    }

    return null;
  }, [pricePlanBillingModel])

  const pricePlanForExternalBillingModelTimeUnit = useMemo(() => {
    if (pricePlanBillingModel === 1) {
      return activity?.pricePlanForExternal?.timeUnit;
    }

    return null;
  }, [pricePlanBillingModel])

  const pricePlanBillingModelTimeUnit = useMemo(() => {
    if (isHouseholdExternal) {
      return pricePlanForExternalBillingModelTimeUnit;
    }

    return pricePlanForNormalBillingModelTimeUnit;
  }, [isHouseholdExternal, pricePlanForExternalBillingModelTimeUnit, pricePlanForNormalBillingModelTimeUnit])

  const pricePlanForNormalPeriod = useMemo(() => {
    if (activity) {
      return activity?.pricePlan?.period;
    }

    return null;
  }, [activity])

  const pricePlanForExternalPeriod = useMemo(() => {
    if (activity) {
      return activity?.pricePlanForExternal?.period;
    }

    return null;
  }, [activity])

  const pricePlanPeriod = useMemo(() => {
    if (isHouseholdExternal) {
      return pricePlanForExternalPeriod;
    }

    return pricePlanForNormalPeriod;
  }, [isHouseholdExternal, pricePlanForExternalPeriod, pricePlanForNormalPeriod])

  const billingPeriodForNormal = useMemo(() => {
    if (activity) {
      return Number(activity?.pricePlan?.billingPeriod?.replace("A_", ""));
    }

    return null;
  }, [activity])

  const billingPeriodForExternal = useMemo(() => {
    if (activity) {
      return activity?.pricePlanForExternal?.billingPeriod;
    }

    return null;
  }, [activity])

  const billingPeriod = useMemo(() => {
    if (isHouseholdExternal) {
      return billingPeriodForExternal;
    }

    return billingPeriodForNormal;
  }, [isHouseholdExternal, billingPeriodForExternal, billingPeriodForNormal])

  const periodSelection = useMemo(() => {
    if (activity) {
      return activity?.periodSelection?.key;
    }

    return null;
  }, [activity])

  console.log({isHouseholdExternal, pricePlanBillingModel})
  
  const columns = useColumns()
  console.log({pricePlanForExternalBillingModel, pricePlanForNormalBillingModelTimeUnit, ccc: activity?.pricePlan?.billingModel})

  return (
    <ActivityContainer>
      <PhotoConainer>
        {activity.photo && <Image src={activity?.photo} alt="" />}
        <ActivityIcon activityType={activity?.activityType?.key} size={90} />
      </PhotoConainer>

      <Container>
        <BText><BankOutlined /> {activity?.organisation?.name} : {activity?.name}</BText>
        <br/>
        <Text>👉 activité ouverte 📆 du {dayjs(activity?.startDate, "YYYY-MM-DD").format("ddd D MMMM YYYY")} au{" "}
        {dayjs(activity?.endDate, "YYYY-MM-DD").format("ddd D MMMM YYYY")} de 🕞{" "}{activity?.startTime?.substring(0,5).replace(":","h")} à {activity?.endTime?.substring(0,5).replace(":","h")}</Text>
        <XLText>Informations</XLText>
        <SText>
          {activity?.description || "Vide"}
          {/* La commune a fait le choix de faire confectionner les repas par son
          propre service de restauration communale, ce qui est un gage de
          qualité aussi bien gustative que nutritionnelle. Les repas sont
          préparés avec un maximum de produits frais, de saison, locaux et bio
          dans la mesure du possible. Ils respectent les règles de l’équilibre
          alimentaire, mais aussi le goût des enfants. - Les produits sont
          systématiquement retravaillés. - Les menus sont établis pour des
          périodes de 5 semaines. */}
        </SText>
        <XLText>Tarifs</XLText>
        <Table
          columns={columns}
          dataSource={pricePlanItems || []}
          // rowSelection={{
          //   type: selectionType,
          //   ...rowSelection,
          // }}
          // onRowSelectionChange={setRowSelection}
          // isLoading={isLoading}
          // onChange={onTableChange}
          pageSize={6}
          total={0} // length
        />
        
        <div
          style={{
            width: "100%",
            padding: 30,
            marginTop: 20,
            backgroundColor: colors.cvBlueGreyLighter,
            borderRadius: 10,
          }}
        >
          <Text
            style={{
              fontWeight: 700,
              color: colors.cvBlueText
            }}
          >
            {pricePlanBillingModel === 0 && (
              <>
                Tarif
                <Text style={{color: colors.cvRed}}>*</Text> 👉 <span style={{fontWeight:400}}>appliqué par réservation ou présence</span>
              </>
            )}
            {pricePlanBillingModel === 1 && (
              <>
                Tarif
                <Text style={{color: colors.cvRed}}>*</Text> 👉 <span style={{fontWeight:400}}>appliqué au temps passé
                avec un arrondi de {pricePlanBillingModelTimeUnit} minutes</span>
              </>
            )}
            {pricePlanBillingModel === 2 && (
              <>
                {periodSelection === 0 && (
                  <>
                    Tarif
                      <Text style={{color: colors.cvRed}}>*</Text> 👉 <span style={{fontWeight:400}}>forfait appliqué pour toute l'activité et facturé à la 1ère réservation ou présence</span>
                  </>
                )}
                {periodSelection === 1 && (
                  <>
                    Tarif
                      <Text style={{color: colors.cvRed}}>*</Text> 👉 <span style={{fontWeight:400}}>forfait appliqué à
                      chaque semaine dès la 1ère réservation ou présence</span>
                  </>
                )}
                {periodSelection >= 2 && (
                  <>
                    Tarif
                      <Text style={{color: colors.cvRed}}>*</Text> 👉 
                    {!!billingPeriod && (<span style={{fontWeight:400}}> forfait appliqué{" "}
                      {pricePlanPeriod === "A_0" ? "à toute l'activité" : getPeriodSelectionLabel(periodSelection).toLocaleLowerCase()}
                      {" "}et facturé{" "}
                      {getBillingPeriodLabel(billingPeriod).toLocaleLowerCase()}</span>)}
                    {(!billingPeriod) && (<> forfait 🥲 <span style={{color:"red", fontWeight:"normal",}}>... votre organisation n'a pas encore précisé si le forfait s'applique à toute l'activité ou {getPeriodSelectionLabel(periodSelection).toLocaleLowerCase()} et quand il est facturé</span></>)}
                  </>
                )}
              </>
            )}
          </Text>
        </div>
      </Container>
      <Divider></Divider>
      <Footer>
        <XLText>Les contacts</XLText>
        <Space direction="horizontal">
          <Space direction="horizontal" style={{ paddingRight: 10 }}>
            <MailOutlined /> {activity?.email || "Aucun email"}
          </Space>

          <Space direction="horizontal">
            <PhoneOutlined rotate={90} /> {activity?.phone || "Aucun numero de telephone"}
          </Space>
        </Space>
      </Footer>

      <div style={{ textAlign: "center", marginTop: 20, marginBottom: 20 }}>
        <Button
          style={{ selfAlign: "center", width: 266, height: 40 }}
          size="large"
          type="primary"
          onClick={() => {
            setCurrentStep(3)
          }}
        >
          Etape suivante
        </Button>
      </div>
    </ActivityContainer>
  )
}

ActivityDetails.propTypes = {
  activity: PropTypes.oneOfType([PropTypes.object]).isRequired,
}

const MText = styled.div`
  line-height: 22px;
  font-size: 14px;
`

const SText = styled.div`
  line-height: 14px;
  font-size: 12px;
`

const BText = styled.div`
  line-height: 28px;
  font-size: 20px;
  color: #2f4580;
`

const XLText = styled.div`
  line-height: 52px;
  font-size: 16px;
  color: @neutral-5;
`

const Container = styled.div`
  padding: 24px;
  // height: 50vh;
`

const Footer = styled.div`
  padding-left: 24px;
  padding-bottom: 24px;
`

export default ActivityDetails
