import React, { useEffect, useMemo } from 'react'
// import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import Gleap from 'gleap'
import Actualities from '~/containers/Actualities'
import AllReservation from '~/containers/AllReservation'
import Bills from '~/containers/Bills'
import Cart from '~/containers/Cart'
import Children from '~/containers/Children'
import Foyer from '~/containers/Foyer'
import Registration from '~/containers/Registration/registration'
import Reservation from '~/containers/Sub'
import Subscription from '~/containers/Subscription'
import Profile from '~/containers/User/UserProfile/profile'
import authToken from '~/utils/Auth'
import Orders from '~/containers/Orders'
import OrderDetail from '~/containers/User/UserProfile/orderDetail'
import Layout from '../../components/navigation/Layout'
import Footer from './components/Footer'
import Navigation from './components/Navigation'
import { userOrganisationsFetching } from '~/containers/Home/actions'

import { Alert } from "antd";

import config from "../../config";
import { Link } from "react-router-dom";

Gleap.setLanguage('fr')
Gleap.initialize('xOWEP0tEdO2hZoE1LuQWH5DA1oYBWPx2')

const token = authToken.getToken()

// console.log({ token })

if (!token) {
  Gleap.showFeedbackButton(false)
}

const MainLayout = () => {
  const dispatch = useDispatch()
  const { accessToken } = useSelector((state) => state.user.auth)

  // #GLEAP -> Intégration de Gleap
  const user = useSelector((state) => state.user.userInfo.user)
  const { email } = user
  // Gleap / CustomData / Liste des organisations
  const { userOrganisationsList, loading } = useSelector(
    (state) => state.home.organisation,
  )
  console.log({ userOrganisationsList, loading })
  const userOrganisationsListNames = useMemo(
    () => userOrganisationsList?.map((org) => org.name),
    [userOrganisationsList],
  )

  useEffect(() => {
    dispatch(userOrganisationsFetching())
  }, [])

  // #GLEAP ---
  useEffect(() => {
    if (user.alias !== undefined && user.alias !== null && !loading) {
      // #GLEAP -> Intégration de Gleap
      Gleap.identify(user.alias, {
        name: `${user.first_name} ${user.last_name}`,
        email,
        phone: user.mobile,
        customData: {
          src: "PFW",
          organisations: userOrganisationsListNames?.length ? userOrganisationsListNames :["aucune organisation"],
          method: "via Gleap.identify",
        }
      })

      Gleap.attachCustomData({
        src: "PFW",
        organisations: userOrganisationsListNames?.length ? userOrganisationsListNames :["aucune organisation"],
        method: "via Gleap.attachCustomData",
      });

      //
      Gleap.trackEvent('#cV trackEvent::user logged in', { alias: user.alias, name: user.name })

      // 31/05/2023
      Gleap.registerCustomAction((customAction) => {
        if (customAction.name === "CUSTOM_HELP") {
          // https://jrmq2468gzko.cityviz.io/
          Gleap.openHelpCenterCollection("2-aide-portail-familles-sur-ordinateur", true);
        }
      });

      // #GLEAP ---
      Gleap.showFeedbackButton(true)
    } else {
      Gleap.showFeedbackButton(false)
      Gleap.clearIdentity()
    }
  }, [user, loading, userOrganisationsListNames])

  useEffect(() => {
    if (accessToken) {
      authToken.setToken(accessToken)
    }
  }, [accessToken, user])

  return (
    <>
      {config.app.env && config.app.env !== "prod" && (
        <Alert
          message={
            <p style={{ color: "red", padding: 0, margin: 0 }}>
              👉 le 🖥️ PFW est connecté avec le ⚙️ BO{" "} dont l'URL est{" "}
              <strong><span style={{fontSize:"1.1em", textDecoration:"underline",}} >
                    <Link to={window.localStorage.getItem("api")?.replace("api/", "") || config.app.devUrls[0]?.replace("api/", "")}>
                        {window.localStorage.getItem("api")?.replace("api/", "") || config.app.devUrls[0]?.replace("api/", "")}
                    </Link>
              </span></strong>
              . Pour changer de ⚙️ BO ... il suffit de vous déconnecter puis de vous reconnecter.
            </p>
          }
          type="warning"
          showIcon
          style={{ borderRadius: 0, textAlign: "center", fontWeight: 500, width:"100%", zIndex: 11, position: "sticky", top: 0 }}
        />)}
      <Navigation />
      <Layout style={{ backgroundColor: '' }}>
        {/* <Container style={{ backgroundColor: '' }}> */}
        {/* <Content> */}
        <Routes>
          {/* <Route path="/" exact component={Home} /> */}
          <Route path="/" exact element={<Actualities />} />
          <Route path="/profile/*" element={<Profile />} />
          <Route path="/foyer/*" exact element={<Foyer />} />
          <Route path="/reservation/:childAlias?/:orgAlias?" exact element={<Reservation />} />
          <Route path="/allreservation" exact element={<AllReservation />} />
          <Route path="/factures" exact element={<Bills />} />
          <Route path="/commandes" exact element={<Orders />} />
          <Route path="/commandes/:id" exact element={<OrderDetail />} />
          {/* <Route path="/children/new" exact component={NewChild} /> */}
          {/* <Route path="/children/edit/:id" component={ChildProfile} /> */}
          <Route path="/children/:id/*" element={<Children />} />
          <Route
            path="/subscription/:child_alias?/:registration_alias?"
            element={<Subscription />}
          />
          <Route
            path="/registration/:child_alias?/:registration_alias?"
            element={<Registration />}
          />
          <Route path="/cart" element={<Cart />} />
        </Routes>
        {/* </Content> */}
        <Footer />
        {/* </Container> */}
      </Layout>
    </>
  )
}

export default MainLayout
